import Lightbox from '@/Components/Common/Lightbox.jsx';
import NumberTextField from '@/Components/Fields/NumberTextField.jsx';
import PostCodeField from '@/Components/Fields/PostCodeField.jsx';
import RadioGroupField from '@/Components/Fields/RadioGroupField.jsx';
import {Fieldset, FieldsetImage} from '@/Pages/Configurator/Styles/Fieldset.jsx';
import ImagesStyle from '@/Styles/images.js';
import {
    Autocomplete,
    Box,
    FormControlLabel,
    InputAdornment,
    Link as MuiLink,
    Radio,
    TextField,
    Typography,
} from '@mui/material';
import React, {memo} from 'react';

let styles = {
    picture_link: {
        display: 'block',
    },
};

const LandFieldset = ({
    url_brand,
    data,
    errors,
    is_preconfigured = false,
    cities_list,
    landSectionType,
    handleLandSectionTypeChange,
    land_section_type_options,
    handleControlledChange,
    handleConstructionPostCodeChange,
}) => {
    let decor = {image: '/images/pages/configurator/decors/decor01.jpg', width: 1920, height: 872};

    switch (url_brand) {
        case 'ya-k':
            decor = {
                image: '/theme/ya-k/images/pages/configurator/decors/photo-terrain-yak-batir.jpg',
                width: 1920,
                height: 1080,
            };
            styles.picture_link = (theme) => ({
                ...styles.picture_link,
                position: 'relative',
                [theme.breakpoints.down('sm')]: {
                    mb: 4,
                },
                '&::after': {
                    ...ImagesStyle.background_contain('/theme/ya-k/images/icons/soleil.svg'),
                    content: '""',
                    position: 'absolute',
                    right: '10%',
                    bottom: 0,
                    transform: 'translateY(65%)',
                    width: '79px',
                    height: '59px',
                    zIndex: 999,
                    [theme.breakpoints.up('md')]: {
                        left: '10%',
                        right: 'auto',
                        width: '118px',
                        height: '88px',
                    },
                },
            });
            break;
        case 'couleur-villas':
            break;
    }
    return (
        <Fieldset>
            <Box>
                <Typography variant="fancy_h5" component="h2" sx={{textAlign: {xs: 'center', md: 'start'}}}>
                    Mon terrain {is_preconfigured ? ' configuré' : ''}
                </Typography>
            </Box>

            <Lightbox id={'gallery-land'}>
                <MuiLink
                    href={decor.image}
                    data-pswp-width={decor.width}
                    data-pswp-height={decor.height}
                    target="_blank"
                    rel="noreferrer"
                    sx={styles.picture_link}
                >
                    <FieldsetImage src={decor.image} alt="" />
                </MuiLink>
            </Lightbox>

            <Box>
                {!is_preconfigured && (
                    <RadioGroupField
                        fullWidth
                        id="land-section-type"
                        name="land_section_type"
                        sx={{
                            mb: 2,
                            '& .MuiFormControlLabel-asterisk': {
                                display: 'none',
                            },
                        }}
                        RadioGroupProps={{
                            value: landSectionType,
                            sx: {gap: 2},
                            onChange: handleLandSectionTypeChange,
                        }}
                    >
                        {land_section_type_options.map(({label, value}) => (
                            <FormControlLabel key={label} value={value} required control={<Radio />} label={label} />
                        ))}
                    </RadioGroupField>
                )}
                {landSectionType === 'fields' && (
                    <Box>
                        <NumberTextField
                            id="price"
                            name="price"
                            value={data.price}
                            fullWidth
                            required
                            label="Prix"
                            error={!!errors?.price}
                            helperText={errors.price}
                            slotProps={{
                                input: {
                                    endAdornment: <InputAdornment position="end">€</InputAdornment>,
                                },
                            }}
                            sx={{mb: 2}}
                            onChange={handleControlledChange}
                        />

                        <PostCodeField
                            name={'construction_postcode'}
                            value={data.construction_postcode}
                            fullWidth
                            required
                            label="Code postal de construction"
                            error={!!errors?.construction_postcode}
                            helperText={errors.construction_postcode}
                            sx={{mb: 2}}
                            onChange={handleConstructionPostCodeChange}
                        />

                        <Autocomplete
                            id="construction_location_id"
                            freeSolo
                            options={cities_list}
                            getOptionLabel={(option) => option || ''}
                            value={data.construction_location_id}
                            onChange={(e, value) =>
                                handleControlledChange({
                                    name: 'construction_location_id',
                                    value,
                                })
                            }
                            sx={{mb: 2}}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    name="construction_location_id"
                                    label="Ville de construction"
                                    fullWidth
                                    required
                                    error={!!errors?.construction_location_id}
                                    helperText={errors.construction_location_id}
                                />
                            )}
                        />
                    </Box>
                )}
            </Box>
        </Fieldset>
    );
};

export default memo(LandFieldset);
